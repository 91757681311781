import dayjs from "dayjs";
import {
  addDocumentContractor,
  addInventoryDocumentBitrix,
  addStockReceiptBitrix,
  cancleProcessDocument,
  getDocumentById,
  getStockReceiptBitrix,
  processDocument,
  updateStockReceiptBitrix,
} from "../api/Bitrix24/inventory.api";
import {
  addProductPrice,
  getPriceBySkuId,
  updatePrice,
} from "../api/Bitrix24/price.api";
import {
  addProductBitrix,
  addProductSkuBitrix,
  getBitrixProductByCode,
  getProductSkuByProductID,
  getSkuByCode,
  updateProductSkuBitrix,
} from "../api/Bitrix24/product.api";
import { getAuth } from "../api/KiotViet/auth.api";
import { getProducts } from "../api/KiotViet/product.api";
import { getProductGroupMT } from "../api/MasterData/productGroup.api";
import { checkExpiredToken, convertProductParents } from "./utils";

const productKiotParams = {
  pageSize: 10,
  orderBy: "createdDate",
  orderDirection: "desc",
  includeInventory: true,
  includeCustomerGroup: true,
  includePricebook: true,
  isActive: true,
};

const delayTime = 500;

const timeout = (cb, time) => {
  setTimeout(() => {
    cb();
  }, time || 1000);
};

export const updateProductByWebhook = (product) => {
  handleRun(product);
};

const handleRun = async () => {
  handlePrepare();
};

//#region BẮT ĐẦU QUY TRÌNH ADD
const handlePrepare = async () => {
  handleGetProductsKiot([], 0, false);
};

const addProductDone = () => {
  // Lấy sản phẩm theo ngày cập nhật mới
  handleGetProductsKiot([], 0, true);
};

// Lấy sản phẩm kiot
const handleGetProductsKiot = (products, currentItem, isUpdate) => {
  if (isUpdate) {
    const params = {
      ...productKiotParams,
      lastModifiedFrom: dayjs(new Date()).format("YYYY-MM-DD"),
    };
    getProducts(params, currentItem).then(async ({ data }) => {
      const next = currentItem + data?.data?.length;

      let list = [...products, ...data.data];
      console.log("LIST PRODUCTS KIOT ===> ", list);

      if (list && list?.length > 0) {
        if (list?.length < data.total) {
          setTimeout(() => {
            // Tiếp tục lấy sản phẩm kiot
            handleGetProductsKiot(list, next, isUpdate);
          }, delayTime);
        } else {
          // Cập nhật sku
          handleUpdateSku(list, 0);
        }
      }
    });
  } else {
    getProducts(productKiotParams, currentItem)
      .then(async ({ data }) => {
        const next = currentItem + data?.data?.length;

        let list = [...products, ...data.data];
        console.log("LIST PRODUCTS KIOT ===> ", list);

        if (list && list?.length > 0) {
          const dateA = new Date(list[list?.length - 1]?.createdDate);
          const dateB = new Date(dayjs(new Date()).format("YYYY-MM-DD"));
          if (dateA > dateB) {
            setTimeout(() => {
              // Tiếp tục lấy sản phẩm kiot
              handleGetProductsKiot(list, next, false);
            }, delayTime);
          } else {
            // Chuyển list product về dạng cha con
            const productWithChildren = await convertProductParents(list);
            // Thêm sản phẩm
            handleAddProduct(productWithChildren);
          }
        }
      })
      .catch((err) => {
        setTimeout(() => {
          handleGetProductsKiot(products, currentItem);
        }, delayTime);
      });
  }
};

// Thêm sản phẩm
const handleAddProduct = async (productWithChildren, indexProduct) => {
  const index = indexProduct || 0;
  if (index <= productWithChildren?.length - 1) {
    const currItem = productWithChildren[index];

    // Kiểm tra sản phẩm đã có chưa
    const response = await getBitrixProductByCode(currItem.code);
    // Nếu có rồi thì nexts
    if (response.data?.result?.[0]) {
      setTimeout(() => {
        handleAddProduct(productWithChildren, index + 1);
      }, delayTime);
      return;
    } else {
      // Nếu chưa có product thì add
      // const isExisted = await getSkuByCode(product.Code);
      getProductGroupMT().then((response) => {
        const groupById = response.data?.find(
          (item) => currItem?.categoryId === item.idK
        )?.idB;

        const skuReq = {
          PROPERTY_378: currItem?.code, // Code
          NAME: currItem?.name,
          CODE: currItem?.code, //fullName
          DESCRIPTION: currItem?.description, //description
          PROPERTY_372: groupById, //categoryId
          SECTION_ID: "128",
          CURRENCY_ID: "VND",
          PROPERTY_380:
            "Sản phẩm được tạo tự động bởi hệ thống đồng bộ Kiot To Bitrix",
        };
        addProductBitrix(skuReq)
          .then((response) =>
            // Thêm SKU
            setTimeout(() => {
              handleAddSku(
                response.data.result,
                currItem.children,
                currItem?.basePrice,
                0,
                [],
                productWithChildren,
                index + 1
              );
            }, delayTime)
          )
          .catch((err) => {
            // Thêm sản phẩm lại
            setTimeout(() => {
              handleAddProduct(productWithChildren, index);
            }, delayTime);
          });
      });
    }
  } else {
    setTimeout(() => {
      addProductDone();
    }, delayTime);
  }
};

// Thêm SKU
const handleAddSku = async (
  productId,
  productSkus,
  basePrice,
  indexSku,
  skuIds,
  productWithChildren,
  indexProduct
) => {
  const index = indexSku || 0;
  if (index <= productSkus?.length - 1) {
    const currItem = productSkus[index];
    const listSkuId = skuIds;
    const amount = currItem.inventories?.reduce(
      (prev, curr) => prev + curr?.onHand,
      0
    );

    const productItem = {
      code: currItem.code,
      iblockId: "26",
      measure: "9",
      name: currItem?.fullName,
      purchasingCurrency: "VND",
      purchasingPrice: currItem?.cost, // giá mua
      subscribe: "Y",
      xmlId: currItem.id,
      id: currItem.id,
      parentId: productId,
      property386: { value: amount > 0 ? "Yes" : "No" },
    };

    // API THÊM SKU
    addProductSkuBitrix(productItem)
      .then((response) => {
        // Thêm giá bán SKU
        setTimeout(() => {
          handleAddProductPrice(
            response.data.result?.offer.id,
            currItem,
            productId,
            productSkus,
            basePrice,
            index + 1,
            listSkuId,
            productWithChildren,
            indexProduct
          );
        }, delayTime);
      })
      .catch((err) => {
        // Thêm sku lại
        setTimeout(() => {
          handleAddSku(
            productId,
            productSkus,
            basePrice,
            indexSku,
            skuIds,
            productWithChildren,
            indexProduct
          );
        }, delayTime);
      });
  } else {
    // Tạo document cho sản phẩm
    setTimeout(() => {
      handleAddDocument(skuIds, productWithChildren, indexProduct);
    }, delayTime);
  }
};

// Cập nhật SKU
const handleUpdateSku = async (skus, indexSku) => {
  const index = indexSku || 0;
  if (index <= skus?.length - 1) {
    const currItem = skus[index];
    const amount = currItem.inventories?.reduce(
      (prev, curr) => prev + curr?.onHand,
      0
    );
    getSkuByCode(currItem.code).then((response) => {
      const productId = response.data?.result?.offers?.[0]?.id;
      const skuReq = {
        code: currItem.code,
        iblockId: "26",
        measure: "9",
        name: currItem?.fullName,
        purchasingCurrency: "VND",
        purchasingPrice: currItem?.cost, // giá mua
        property386: { value: amount > 1 ? "Yes" : "No" }, // Nếu số lượng > 1 thì cập nhật tình trạng đã tồn kho
      };

      // API CẬP NHẬT SKU
      updateProductSkuBitrix(productId, skuReq)
        .then((response) => {
          // Cập nhật giá bán SKU
          setTimeout(() => {
            handleUpdateProductPrice(skus, index, productId);
          }, delayTime);
        })
        .catch((err) => {
          // Thêm sku lại
          setTimeout(() => {
            handleUpdateSku(skus, indexSku);
          }, delayTime);
        });
    });
  }
};

// Thêm giá bán
const handleAddProductPrice = (
  addedSkuId,
  currSku,
  productId,
  productSkus,
  basePrice,
  indexSku,
  skuIds,
  productWithChildren,
  indexProduct
) => {
  // API Thêm giá bán
  addProductPrice({
    catalogGroupId: 2,
    currency: "VND",
    price: basePrice,
    productId: addedSkuId,
  })
    .then(() => {
      // Tổng số lượng của SKU
      const amount = currSku.inventories?.reduce(
        (prev, curr) => prev + curr?.onHand,
        0
      );
      const cost = currSku.inventories?.find((inv) => inv.cost > 0)?.cost;
      skuIds.push({
        id: addedSkuId,
        amount: amount,
        purchasePrice: cost || 0, // giá mua
        salePrice: basePrice, // giá bán
      });
      // Tiếp tục thêm SKU
      setTimeout(() => {
        handleAddSku(
          productId,
          productSkus,
          basePrice,
          indexSku,
          skuIds,
          productWithChildren,
          indexProduct
        );
      }, delayTime);
    })
    .catch(() => {
      // Thêm giá bán lại
      setTimeout(() => {
        handleAddProductPrice(
          addedSkuId,
          currSku,
          productId,
          productSkus,
          basePrice,
          indexSku,
          skuIds,
          productWithChildren,
          indexProduct
        );
      });
    }, delayTime);
};

// Cập nhật giá bán
const handleUpdateProductPrice = async (skus, indexSku, productId) => {
  const sku = skus[indexSku];
  // Lấy Giá theo sku id
  await getPriceBySkuId(productId)
    .then(async ({ data }) => {
      await updatePrice(sku, data.result.prices?.[0]?.id).then(() => {
        // Cập nhật stock
        // setTimeout(() => {
        //   handleUpdateStock(skus, indexSku);
        // }, delayTime);
        setTimeout(() => {
          getStockByProductId(skus, indexSku, productId);
        }, delayTime);
      });
    })
    .catch((err) => {
      // Cập nhật giá bán lại
      setTimeout(() => {
        handleUpdateProductPrice(skus, indexSku);
      }, delayTime);
    });
};

// Tạo document cho sản phẩm
const handleAddDocument = async (
  skuIds, // {id, amount, cost}
  productWithChildren,
  indexProduct
) => {
  // Nếu có tồn kho thì tạo document
  const total = skuIds?.reduce(
    (prev, curr) => prev + curr?.purchasePrice * curr.amount,
    0
  );
  const document = {
    docType: "A", // A = Stock receipt
    contractorId: "1", // vendor
    responsibleId: "1",
    currency: "VND",
    total: total,
  };

  // API Thêm document
  addInventoryDocumentBitrix(document)
    .then((response) => {
      // Thêm tồn kho
      setTimeout(() => {
        handleAddStockReceipt(
          response.data.result.document.id,
          skuIds,
          0,
          productWithChildren,
          indexProduct
        );
      }, delayTime);
    })
    .catch((err) => {
      // Thêm document lại
      setTimeout(() => {
        handleAddDocument(
          skuIds, // {id, amount, cost}
          productWithChildren,
          indexProduct
        );
      }, delayTime);
    });
};

// Thêm tồn kho
const handleAddStockReceipt = async (
  docId,
  skuIds,
  indexStock,
  productWithChildren,
  indexProduct
) => {
  const index = indexStock || 0;
  if (index <= skuIds?.length - 1) {
    const currItem = skuIds[index];
    const stockReceipt = {
      docId: docId,
      storeFrom: 0,
      storeTo: 10, // 10 = Kho tổng sản phẩm
      elementId: currItem.id,
      amount: currItem.amount > 0 ? currItem.amount : 1,
      purchasingPrice: currItem.purchasePrice,
    };
    // API  thêm tồn kho
    addStockReceiptBitrix(stockReceipt)
      .then((response) => {
        // Tiếp tục thêm tồn kho
        setTimeout(() => {
          handleAddStockReceipt(
            docId,
            skuIds,
            index + 1,
            productWithChildren,
            indexProduct
          );
        }, delayTime);
      })
      .catch((err) => {
        // Thêm tồn kho lại
        setTimeout(() => {
          handleAddStockReceipt(
            docId,
            skuIds,
            indexStock,
            productWithChildren,
            indexProduct
          );
        }, delayTime);
      });
  } else {
    // Tạo liên kết document và nhà cung cấp
    setTimeout(() => {
      handleAddDocumentContractor(docId, productWithChildren, indexProduct);
    }, delayTime);
  }
};

// Lấy stock bằng id sản phẩm => Lấy được docId
const getStockByProductId = (skus, indexSku, productId) => {
  const sku = skus[indexSku];
  getStockReceiptBitrix(productId)
    .then((response) => {
      setTimeout(() => {
        handleCancleProcess(
          skus,
          indexSku,
          response.data.result?.documentElements?.[0]?.docId,
          response.data.result?.documentElements?.[0]?.id
        );
      }, delayTime);
    })
    .catch(() => {
      setTimeout(() => {
        getStockByProductId(skus, indexSku, productId);
      }, delayTime);
    });
};

// Tắt process document để update stock
const handleCancleProcess = (skus, indexSku, docId, stockId) => {
  getDocumentById(docId).then((response) => {
    const document = response.data.result?.documents;
    // Nếu document đang process thì hủy process để update stock
    if (document && document?.length > 0 && document?.[0]?.status === "Y") {
      setTimeout(() => {
        cancleProcessDocument(docId).then(() => {
          setTimeout(() => {
            handleUpdateStock(skus, indexSku, docId, stockId);
          }, delayTime);
        });
      }, delayTime);
    } else {
      // Nếu chưa có thì update stock luôn
      setTimeout(() => {
        handleUpdateStock(skus, indexSku, docId, stockId);
      }, delayTime);
    }
  });
};

// Cập nhật tồn kho
const handleUpdateStock = async (skus, indexSku, docId, stockId) => {
  const sku = skus[indexSku];
  const amount = sku.inventories?.reduce(
    (prev, curr) => prev + curr?.onHand,
    0
  );
  console.log(amount);
  const stockReq = {
    docId: docId,
    amount: amount <= 0 ? 1 : amount,
    purchasingPrice: sku.inventories?.find((inv) => inv.cost > 0)?.cost, // giá mua
  };
  await updateStockReceiptBitrix(stockId, stockReq).then(() => {
    // Quay lại cập nhật tiếp sku
    setTimeout(() => {
      handleReProcessDocument(skus, indexSku, docId);
    }, delayTime);
  });
};

// Tạo liên kết document và nhà cung cấp
const handleAddDocumentContractor = (
  docId,
  productWithChildren,
  indexProduct
) => {
  const documentContractor = {
    documentId: docId,
    entityTypeId: 3,
    entityId: 25788,
  };
  // API Tạo liên kết document và nhà cung cấp
  addDocumentContractor(documentContractor)
    .then((response) => {
      // Kích hoạt document
      setTimeout(() => {
        handleProcessDocument(docId, productWithChildren, indexProduct);
      }, delayTime);
    })
    .catch((err) => {
      // API Tạo liên kết document và nhà cung cấp lại
      setTimeout(() => {
        handleAddDocumentContractor(docId, productWithChildren, indexProduct);
      }, delayTime);
    });
};

// Kích hoạt document
const handleProcessDocument = async (
  docId,
  productWithChildren,
  indexProduct
) => {
  // API Kích hoạt document
  try {
    processDocument(docId);
    backToAddProduct(productWithChildren, indexProduct);
  } catch (e) {
    backToAddProduct(productWithChildren, indexProduct);
  }
};

// Kích hoạt lại document
const handleReProcessDocument = async (skus, indexSku, docId) => {
  // API Kích hoạt document
  try {
    processDocument(docId);
    handleUpdateSku(skus, indexSku + 1);
  } catch (e) {
    handleUpdateSku(skus, indexSku + 1);
  }
};

const backToAddProduct = (productWithChildren, indexProduct) => {
  // Quay lại tiếp tục thêm sản phẩm
  setTimeout(() => {
    handleAddProduct(productWithChildren, indexProduct);
  }, delayTime);
};
//#endregion KẾT THÚC QUY TRÌNH
