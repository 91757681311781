import axios from "axios";
import { baseUrl } from "../../constants/url";

const endpoint = "/logs";

export const getContactLogs = async () => {
  const response = await axios.get(`${baseUrl}${endpoint}`);
  return response;
};
export const getLastContactLog = async () => {
  const response = await axios.get(`${baseUrl}${endpoint}/last-sync-contact`);
  return response;
};
export const addContactLog = async (contactLog) => {
  const response = await axios.post(`${baseUrl}${endpoint}/add`, contactLog);
  return response;
};
export const addContactLogTest = async () => {
  const response = await axios.post(`${baseUrl}${endpoint}/add`);
  return response;
};
