import { getBitrixContactByPhone } from "../api/Bitrix24/contact.api";
import {
  addDealToBitrix,
  addProductToDealBitrix,
} from "../api/Bitrix24/deal.api";
import {
  getBitrixProductByCode,
  getSkuByCode,
} from "../api/Bitrix24/product.api";
import { convertPhoneNumber } from "./utils";

export const updateDealByWebhook = (deal) => {
  handleSync(deal);
};

const handleSync = async (deal) => {
  const phoneNumber = convertPhoneNumber(deal?.CustomerCode);

  await getBitrixContactByPhone(phoneNumber)
    .then((response) => {
      const contactId = response.data?.result?.[0]?.ID;
      const title = `KIOTVIET - ${deal?.BranchName} - ${deal?.Code} - ${deal?.CustomerName}`;
      const dealReq = {
        TITLE: title,
        TYPE_ID: "SALE",
        STAGE_ID: "C60:NEW",
        CONTACT_ID: contactId || "",
        OPENED: "Y",
        ASSIGNED_BY_ID: "1",
        CURRENCY_ID: "VND",
        CATEGORY_ID: "60",
        BEGINDATE: deal?.CreatedDate,
      };

      addDealToBitrix(dealReq)
        .then((response) => {
          handleGetProductByInvoice(
            deal?.InvoiceDetails,
            0,
            [],
            response.data.result
          );
          // API LƯU LOG
        })
        .catch(() => setTimeout(() => handleSync(deal), 500));
    })
    .catch(() => setTimeout(() => handleSync(deal), 500));
};

// Lấy danh sách sản phẩm theo hóa đơn
const handleGetProductByInvoice = (
  invoicesDetail,
  index,
  listProduct,
  dealId
) => {
  if (index <= invoicesDetail?.length - 1) {
    getSkuByCode(invoicesDetail[index]?.ProductCode)
      .then((response) => {
        console.log(response.data);
        listProduct.push(response.data?.result?.offers?.[0]);
        setTimeout(
          () =>
            handleGetProductByInvoice(
              invoicesDetail,
              index + 1,
              listProduct,
              dealId
            ),
          500
        );
      })
      .catch(() =>
        setTimeout(
          () =>
            handleGetProductByInvoice(
              invoicesDetail,
              index,
              listProduct,
              dealId
            ),
          500
        )
      );
  } else {
    setTimeout(
      () => handleAddProductToDeal(invoicesDetail, dealId, listProduct),
      500
    );
  }
};

const handleAddProductToDeal = (invoicesDetail, dealId, listProduct) => {
  const products = invoicesDetail?.map((invoice) => {
    console.log(
      listProduct?.find((product) => product?.code === invoice?.ProductCode)?.id
    );
    return {
      PRODUCT_ID: listProduct?.find(
        (product) => product?.code === invoice?.ProductCode
      )?.id,
      PRICE: invoice?.Price,
      QUANTITY: invoice?.Quantity,
      MEASURE_CODE: 796,
      MEASURE_NAME: "Cái",
      DISCOUNT_SUM: invoice?.Discount,
    };
  });
  addProductToDealBitrix(products, dealId)
    .then(() => {})
    .catch(() => setTimeout(() => handleAddProductToDeal(dealId), 500));
};
