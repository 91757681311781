import { Layout, Menu } from "antd";
import React from "react";
const { Header } = Layout;
export default function AppHeader() {
  const items = [
    {
      key: "contact",
      label: "Contact",
    },
    {
      key: "deal",
      label: "Deal",
    },
    {
      key: "product",
      label: "Product",
    },
  ];
  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div className="demo-logo" />
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={["contact"]}
        items={items}
      />
    </Header>
  );
}
