import {
  addContactToBitrix,
  getBitrixContactByPhone,
  updateContactToBitrix,
} from "../api/Bitrix24/contact.api";
import contactGroupConstant from "../constants/contactGroup";
import usersConstant from "../constants/user";
import { convertPhoneNumber } from "./utils";

export const updateContactByWebhook = (contact) => {
  handleSync(contact);
};

const handleSync = async (contact) => {
  console.log(contact?.ContactNumber);
  const phoneNumber = convertPhoneNumber(contact?.ContactNumber);

  await getBitrixContactByPhone(phoneNumber)
    .then((response) => {
      const responseItem = response.data?.result?.[0];

      let typeId = contactGroupConstant?.find(
        (x) => x.name === contact?.Groups
      )?.idBitrix;

      const contactReq = {
        NAME: contact?.Name,
        PHONE: [
          {
            VALUE: phoneNumber,
            VALUE_TYPE: "MOBILE",
          },
        ],
        BIRTHDATE: contact?.BirthDate || "",
        ADDRESS: contact?.Address,
        EMAIL: [{ VALUE_TYPE: "OTHER", VALUE: contact?.Email || "" }],
        TYPE_ID: typeId,
        ASSIGNED_BY_ID: "1",
      };

      if (responseItem) {
        updateContactToBitrix(contactReq, responseItem.ID)
          .then((response) => {
            console.log(response.data);
            // API LƯU LOG
          })
          .catch(() => setTimeout(() => handleSync(contact), 500));
      } else {
        addContactToBitrix(contactReq)
          .then((response) => {
            console.log(response.data);
            // API LƯU LOG
          })
          .catch(() => setTimeout(() => handleSync(contact), 500));
      }
    })
    .catch(() => setTimeout(() => handleSync(contact), 500));
};
