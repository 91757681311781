// const contactGroupConstant = {
//   // kiot: bitrix
//   KHÁCHLẺ: "CLIENT",
//   "Nhóm 10%-1": "1",
//   "Nhóm 10%-2": "2",
//   "Nhóm 05%-1": "3",
//   "Nhóm 05%-2": "4",
//   "Khách Vip": "5",
//   "MEMBER 5%": "UC_85PXE2",
//   "MEMBER 10%": "UC_QZ79BR",
//   "VIP 5 - MẤT VIP": "UC_NETFHU",
//   "VIP 10 - MẤT VIP": "UC_0RBS5Q",
// };
const contactGroupConstant = [
  { id: 304393, name: "Nhóm 10%-2", idBitrix: "2" },
  { id: 304394, name: "Nhóm 10%-1", idBitrix: "1" },
  { id: 304396, name: "Nhóm 05%-2", idBitrix: "4" },
  { id: 304395, name: "Nhóm 05%-1", idBitrix: "3" },
  { id: 304588, name: "MEMBER 5%", idBitrix: "UC_85PXE2" },
  { id: 304590, name: "MEMBER 10%", idBitrix: "UC_QZ79BR" },
  { id: 304589, name: "Khách Vip", idBitrix: "5" },
  { id: 304586, name: "KHÁCH LẺ", idBitrix: "CLIENT" },
];
export default contactGroupConstant;
