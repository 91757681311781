import axios from "axios";
import { baseUrl } from "../../constants/url";

const endpoint = "/invoices";
// const kiotToken = localStorage.getItem("kiotToken");

export const getBillKiot = async (querykey, currentItem, kiotToken) => {
  console.log(kiotToken);
  const response = await axios.get(`${baseUrl}${endpoint}`, {
    headers: {
      Retailer: "cosette",
      Authorization: `Bearer ${kiotToken}`,
    },
    params: { ...querykey, currentItem: currentItem },
  });
  return response;
};
