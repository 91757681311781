export const kiotVietUrl = "https://public.kiotapi.com";
export const bitrixUrl = "https://cosette.bitrix24.com";
// export const baseUrl =
//   process.env.NODE_ENV === "production"
//     ? "https://bitrixconnect-910e3f08721f.herokuapp.com"
//     : "http://localhost:5000";
// export const WS_URL =
//   process.env.NODE_ENV === "production"
//     ? "https://bitrixconnect-910e3f08721f.herokuapp.com"
//     : "http://localhost:5000";

export const baseUrl = "https://bitrixconnect-910e3f08721f.herokuapp.com";
export const WS_URL = "https://bitrixconnect-910e3f08721f.herokuapp.com";
