export const convertPhoneNumber = (phone) => {
  const phoneNumber = String(phone).replaceAll(" ", "").replaceAll("-", "");
  return phoneNumber;
};
export const checkExpiredToken = () => {
  const now = Date.now();
  const expiredDate = Date.parse(localStorage.getItem("expiredDate"));
  console.log(now, expiredDate, now >= expiredDate);
  if (now < expiredDate) {
    return true;
  } else {
    return false;
  }
};
export const runInterval = (id, setId, delayTime, resolve, reject) => {
  if (!id) {
    setId(
      setInterval(() => {
        if (checkExpiredToken()) {
          resolve();
        } else {
          clearInterval(id);
          localStorage.clear("kiotToken");
          localStorage.clear("expiredDate");
          reject();
        }
      }, [delayTime])
    );
  }
};

// CHUYỂN SẢN PHẨM VỀ DẠNG CHA CON
export const convertProductParents = async (productsKiot) => {
  let parents = await productsKiot
    .filter((product) => !product.masterProductId)
    ?.map((item) => item);

  let productWithChildren = await parents?.map((parent) => ({
    ...parent,
    children: convertProductChildren(productsKiot, parent.id),
  }));

  console.log(productWithChildren);
  return productWithChildren;
};

const convertProductChildren = (productsKiot, parentId) => {
  const children = productsKiot.filter(
    (product) => product.masterProductId === parentId || product.id === parentId
  );
  return children;
};
