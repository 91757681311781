import axios from "axios";

export const getBitrixDeals = async () => {
  const data = await axios.post(
    "https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/crm.deal.list",
    { order: { ID: "DESC" } }
  );

  return data;
};

export const addDealToBitrix = async (deal) => {
  const data = await axios.post(
    "https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/crm.deal.add",
    {
      fields: deal,
    }
  );
  return data;
};

export const addProductToDealBitrix = async (products, id) => {
  const data = await axios.post(
    "https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/crm.deal.productrows.set",
    {
      id: id,
      rows: products,
    }
  );
  return data;
};
