import { Button, Flex, Form, Input, Modal, Typography } from "antd";
import React, { useContext, useState } from "react";
import { getAuth } from "../../../api/KiotViet/auth.api";
import { AppContext } from "../../../context/AppContext";
import "./loginModal.scss";
const { Title } = Typography;
export default function LoginModal() {
  const [loading, setLoading] = useState(false);
  const { updateKiotToken, isOpenLogin, updateOpenLogin } =
    useContext(AppContext);

  const handleLogin = async (field) => {
    setLoading(true);
    await getAuth(field)
      .then((response) => {
        updateKiotToken(response.data.access_token);
        let d = new Date();
        d.setMinutes(d.getMinutes() + response.data.expires_in / 60);
        localStorage.setItem("expiredDate", d);
        setLoading(false);
        updateOpenLogin(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Modal
      centered
      open={isOpenLogin}
      wrapClassName="login-modal"
      footer={null}
    >
      <Flex align="center" gap="middle" vertical>
        <Title level={4}>Đăng nhập</Title>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleLogin}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Flex>
    </Modal>
  );
}
