import { Flex, Layout, Typography } from "antd";
import React from "react";
import AddContactBitrix from "../Button/AddContactBitrix";
import UpdateProductBitrix from "../Button/UpdateProductBitrix";
import Contacts from "../List/Contact";
import "./appBody.scss";
const { Content } = Layout;
const { Title } = Typography;
export default function AppBody() {
  return (
    <Content>
      <Flex className="app-body" justify="flex-start" gap={20}>
        <Flex vertical align="flex-start" className="function-panel" gap={10}>
          <Title level={4}>Menu</Title>
          <UpdateProductBitrix />
          {/* <AddProductBitrix /> */}
          {/* <AddProductGroupMasterData /> */}
          {/* <SyncProductGroup /> */}
          <AddContactBitrix />
        </Flex>
        <Contacts />
      </Flex>
    </Content>
  );
}
