import { Layout } from "antd";
import React, { useContext, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import "./App.scss";
import AppBody from "./components/Body/AppBody";
import AppHeader from "./components/Header";
import LoginModal from "./components/Modal/Login";
import SyncingModal from "./components/Modal/Syncing";
import { WS_URL } from "./constants/url";
import { AppContext } from "./context/AppContext";
import { updateContactByWebhook } from "./utils/syncContact";
import { updateDealByWebhook } from "./utils/syncDeal";
import { updateProductByWebhook } from "./utils/syncProduct";
import { checkExpiredToken } from "./utils/utils";
export default function App() {
  const kiotToken = localStorage.getItem("kiotToken");
  const {
    isOpenLogin,
    setIsOpenLogin,
    isSyncing,
    setIsSyncing,
    updateOpenLogin,
  } = useContext(AppContext);
  useEffect(() => {
    const socket = io(WS_URL, {
      cors: {
        origin: WS_URL,
      },
    });
    let timeout;
    socket.on("connection", (data) => {
      console.log("Connected!", data);
    });

    // Listen for incoming messages
    socket.on("message", (newMessage) => {
      console.log(newMessage);
      if (newMessage.__type.includes("WebhookCustomerUpdateRes")) {
        updateContactByWebhook(newMessage);
      } else if (newMessage.__type.includes("WebhookInvoiceUpdateRes")) {
        updateDealByWebhook(newMessage);
      } else if (newMessage.__type.includes("WebhookProductUpdateRes")) {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          updateProductByWebhook(newMessage);
        }, 2000);
      }
    });

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  // useEffect(() => {
  //   if (kiotToken) {
  //     updateOpenLogin(false);
  //   } else {
  //     setIsSyncing(false);
  //     updateOpenLogin(true);
  //   }
  // }, [kiotToken, updateOpenLogin, setIsSyncing]);

  useEffect(() => {
    const interval = setInterval(() => {
      const isExpired = checkExpiredToken();
      console.log("isExpired", isExpired);
      if ((!isExpired || !kiotToken) && !isOpenLogin) {
        setIsOpenLogin(true);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isOpenLogin, kiotToken, setIsOpenLogin]);

  return (
    <Layout>
      <AppHeader />
      <AppBody />
      {/* {kiotToken && <AppFooter />} */}
      {isOpenLogin && <LoginModal />}
      {isSyncing && <SyncingModal />}
    </Layout>
  );
}
