import { Flex, Table, Typography } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { getContactLogs } from "../../../api/Log/ContactLog.api";
import { AppContext } from "../../../context/AppContext";
import "./Contacts.scss";
const { Title, Text } = Typography;
const Contacts = () => {
  const kiotToken = localStorage.getItem("kiotToken");
  const [addedContactLog, setAddedContactLog] = useState([]);
  const { isSyncing } = useContext(AppContext);

  const columns = [
    {
      title: "ID",
      dataIndex: "contactBitrixId",
      key: "contactBitrixId",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Ngày tạo log",
      dataIndex: "logCreateDate",
      key: "logCreateDate",
      render: (text) => <Text>{dayjs(text).format("MM/DD/YYYY")}</Text>,
    },
  ];

  useEffect(() => {
    if (kiotToken && !isSyncing) {
      getContactLogs().then((response) => {
        setAddedContactLog(response?.data);
      });
    }
  }, [kiotToken, isSyncing]);
  return (
    <Flex vertical className="app-body-content">
      <Title level={4}>Lịch sử đồng bộ khách hàng</Title>
      <Table
        rowKey={(record) => record.contactBitrixId}
        columns={columns}
        dataSource={addedContactLog}
      />
      {/* <List
        itemLayout="horizontal"
        dataSource={addedContactLog}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              title={
                <Flex>
                  <Typography.Text style={{ color: "red" }}>
                    {index + 1}
                  </Typography.Text>
                  <Typography.Text>{`#${item?.contactBitrixId} - ${item?.name} - ${item?.phone}`}</Typography.Text>
                </Flex>
              }
              description={
                <Typography.Text>
                  {dayjs(item?.logCreateDate).format("DD/MM/YYYY")}
                </Typography.Text>
              }
            />
          </List.Item>
        )}
      /> */}
    </Flex>
  );
};
export default Contacts;
