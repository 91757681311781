import axios from "axios";

export const addInventoryDocumentBitrix = async (document) => {
  const response = await axios.post(
    `https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.document.add`,
    { fields: document }
  );
  return response;
};

export const addStockReceiptBitrix = async (stockReceipt) => {
  const response = await axios.post(
    `https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.document.element.add`,
    { fields: stockReceipt }
  );
  return response;
};

export const getStockReceiptBitrix = async (productId) => {
  const response = await axios.post(
    `https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.document.element.list`,
    {
      filter: {
        elementId: productId,
      },
    }
  );
  return response;
};

export const updateStockReceiptBitrix = async (stockId, params) => {
  const response = await axios.post(
    "https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.document.element.update",
    {
      id: stockId,
      fields: params,
    }
  );
  return response;
};

export const addDocumentContractor = async (documentContractor) => {
  const response = await axios.post(
    `https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.documentcontractor.add`,
    { fields: documentContractor }
  );
  return response;
};
export const getDocumentById = async (docId) => {
  const response = await axios.post(
    `https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.document.list`,
    {
      filter: {
        id: docId,
      },
    }
  );
  return response;
};
export const processDocument = async (docId) => {
  const response = await axios.post(
    `https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.document.confirm`,
    { id: docId }
  );
  return response;
};
export const cancleProcessDocument = async (docId) => {
  const response = await axios.post(
    `https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.document.unconfirm`,
    { id: docId }
  );
  return response;
};
