import axios from "axios";
export const getBitrixContactByPhone = async (phone) => {
  const data = await axios.post(
    "https://cosette.bitrix24.com/rest/24/eeywr7gxm5z50w4p/crm.contact.list.json",
    { select: ["ID", "PHONE"], filter: { PHONE: phone } }
  );

  return data;
};

export const getBitrixContacts = async (querykey, start) => {
  const data = await axios.post(
    "https://cosette.bitrix24.com/rest/24/eeywr7gxm5z50w4p/crm.contact.list.json",
    { ...querykey, start: start }
  );

  return data;
};

export const addContactToBitrix = async (contact) => {
  const data = await axios.post(
    `https://cosette.bitrix24.com/rest/24/3i09u4h8slzanbsm/crm.contact.add.json`,
    {
      fields: contact,
      params: { REGISTER_SONET_EVENT: "Y" },
    }
  );
  return data;
};
export const updateContactToBitrix = async (contact, id) => {
  const data = await axios.post(
    `https://cosette.bitrix24.com/rest/24/3i09u4h8slzanbsm/crm.contact.update`,
    {
      fields: contact,
      id: id,
    }
  );
  return data;
};
