import axios from "axios";
import { baseUrl } from "../../constants/url";

const endpointGroup = "/kiot/product/groups";
const endpoint = "/kiot/product";
const kiotToken = localStorage.getItem("kiotToken");

export const getProductGroups = async () => {
  const response = await axios.get(`${baseUrl}${endpointGroup}`, {
    headers: {
      Retailer: "cosette",
      Authorization: `Bearer ${kiotToken}`,
    },
    params: {
      includeRemoveIds: true,
      pageSize: 100,
      currentItem: 0,
      orderBy: "categoryName",
      orderDirection: "desc",
    },
  });
  return response;
};

export const getProducts = async (params, currentItem) => {
  const response = await axios.get(`${baseUrl}${endpoint}`, {
    headers: {
      Retailer: "cosette",
      Authorization: `Bearer ${kiotToken}`,
    },
    params: { ...params, currentItem: currentItem },
  });
  return response;
};
export const getProduct = async () => {
  const response = await axios.get(`${baseUrl}${endpoint}/get`, {
    headers: {
      Retailer: "cosette",
      Authorization: `Bearer ${kiotToken}`,
    },
  });
  return response;
};
