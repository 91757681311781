import { Button, DatePicker, Flex, Modal } from "antd";
import Text from "antd/es/typography/Text";
import dayjs from "dayjs";
import React, { useState } from "react";

export default function DatePickerModal({
  open,
  setOpen,
  onStop,
  onStart,
  running,
  setRunning,
}) {
  const [date, setDate] = useState(dayjs(new Date()).format("YYYY/MM/DD"));
  const onChange = (date, dateString) => {
    setDate(dateString);
    // console.log(dayjs(date).format("YYYY-MM-DD"));
  };
  return (
    <Modal
      centered
      open={open}
      width={400}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Flex
        align="center"
        justify="center"
        gap={20}
        vertical
        style={{ width: "100%" }}
      >
        <Text strong>Chọn ngày đồng bộ</Text>
        <DatePicker
          disabled={running}
          value={dayjs(date)}
          onChange={onChange}
          style={{ width: "100%" }}
        />
        <Flex gap={10} justify="center" style={{ width: "100%" }}>
          <Button
            disabled={!running}
            type="text"
            style={{ width: "100%" }}
            onClick={onStop}
          >
            Hủy
          </Button>
          <Button
            disabled={running}
            loading={running}
            type="primary"
            style={{ width: "100%" }}
            onClick={() => {
              setRunning(true);
              onStart(date).then(() => setOpen(false));
            }}
          >
            Đồng bộ
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
