import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import {
  addContactToBitrix,
  getBitrixContactByPhone,
} from "../../api/Bitrix24/contact.api";
import { getBillKiot } from "../../api/KiotViet/bill.api";
import { getContactsKiot } from "../../api/KiotViet/contact.api";
import { addContactLog, getLastContactLog } from "../../api/Log/ContactLog.api";
import contactGroupConstant from "../../constants/contactGroup";
import usersConstant from "../../constants/user";
import { AppContext } from "../../context/AppContext";
import { checkExpiredToken, convertPhoneNumber } from "../../utils/utils";
import { Button } from "antd";
import DatePickerModal from "../Modal/DatePickerModal";
const delayTime = 500;
const delayInterval = 60000;
const billKiotParams = {
  pageSize: 20,
  orderBy: "createdDate",
  orderDirection: "desc",
  fromPurchaseDate: "",
  toPurchaseDate: dayjs(Date.now()).format("YYYY-MM-DD"),
};

const contactKiotParams = {
  pageSize: 20,
  orderBy: "createdDate",
  orderDirection: "desc",
  includeCustomerGroup: true,
};

export default function AddContactBitrix() {
  const [open, setOpen] = useState(false);
  const kiotToken = localStorage.getItem("kiotToken");
  const { setIsSyncing, updateOpenLogin, isSyncing } = useContext(AppContext);


  const handleRun = async (date) => {
    if (checkExpiredToken()) {
      start(date);
    } else {
      localStorage.clear("kiotToken");
      localStorage.clear("expiredDate");
      stop();
    }
  };

  const handlePrepareSync = (date) => {
    handleGetBillKiot([], 0, date);
  };

  const handleGetBillKiot = (contacts, prevNext, lastSyncDate) => {
    const billReq = {
      ...billKiotParams,
      fromPurchaseDate: dayjs(lastSyncDate).format(
        "YYYY-MM-DD"
      ),
    };
    getBillKiot(billReq, prevNext, kiotToken)
      .then(({ data }) => {
        const next = prevNext + data?.data?.length;
        let list = [...contacts, ...data.data];

        console.log("LIST BILL KIOT ===> ", list);

        if (next < data.total) {
          setTimeout(() => {
            handleGetBillKiot(list, next, lastSyncDate);
          }, delayTime);
        } else {
          handleGetContactKiot([], 0, lastSyncDate, list);
        }
      })
      .catch(() => {
        setTimeout(() => {
          handleGetBillKiot(contacts, prevNext, lastSyncDate);
        }, delayTime);
      });
  };

  const handleGetContactKiot = (contacts, prevNext, lastSyncDate, bills) => {
    getContactsKiot(contactKiotParams, prevNext, kiotToken)
      .then(({ data }) => {
        const next = prevNext + data?.data?.length;

        let list = [...contacts, ...data.data];
        console.log("LIST CONTACT KIOT ===> ", list);

        if (list && list?.length > 0) {
          const dateA = new Date(list[list?.length - 1]?.createdDate);
          const dateB = new Date(dayjs(lastSyncDate).format("YYYY-MM-DD"));
          console.log(dateA > dateB);
          if (dateA > dateB) {
            setTimeout(() => {
              handleGetContactKiot(list, next, lastSyncDate, bills);
            }, delayTime);
          } else {
            handleCheckExistContact(list, 0, bills);
          }
        }
      })
      .catch((err) => {
        setTimeout(() => {
          handleGetContactKiot(contacts, prevNext, lastSyncDate, bills);
        }, delayTime);
      });
  };

  const handleCheckExistContact = async (contacts, startAt, bills) => {
    const index = startAt;
    if (index <= contacts?.length - 1) {
      const phoneNumber = convertPhoneNumber(contacts[index]?.contactNumber);
      await getBitrixContactByPhone(phoneNumber).then((response) => {
        const responseItem = response.data?.result?.[0];
        if (!responseItem) {
          handleSync(contacts, index, bills);
        } else {
          setTimeout(() => {
            handleCheckExistContact(contacts, index + 1, bills);
          }, delayTime);
        }
      });
    } else {
      setIsSyncing(false);
    }
  };

  const handleSync = (contacts, index, bills) => {
    const contact = contacts[index];
    // GET PARAMS TO ADD CONTACT BITRIX
    let typeId = contactGroupConstant?.find(
      (x) => x.name === contact?.groups
    )?.idBitrix;
    let soldById = bills?.find(
      (bill) => bill.customerCode === contact?.code
    )?.soldById;
    const params = {
      NAME: contact?.name,
      PHONE: [
        {
          VALUE: convertPhoneNumber(contact?.contactNumber),
          VALUE_TYPE: "MOBILE",
        },
      ],
      BIRTHDATE: contact?.birthDate || "",
      ADDRESS: contact?.address,
      EMAIL: [{ VALUE_TYPE: "OTHER", VALUE: contact?.email || "" }],
      TYPE_ID: typeId,
      ASSIGNED_BY_ID: usersConstant?.find((user) => user.id === soldById)
        ?.idBitrix,
    };

    // CALL API ADD CONTACT BITRIX
    handleAddContactBitrix(params, contacts, index);
  };

  const handleAddContactBitrix = (params, contacts, index) => {
    addContactToBitrix(params)
      .then((response) => {
        const newContactLog = {
          contactBitrixId: response.data?.result,
          name: params.NAME,
          phone: params?.PHONE?.[0]?.VALUE,
          typeLog: "add",
          logCreateDate: dayjs(Date.now()).format("DD/MM/YYYY"),
        };
        // ADD LOG
        handleAddContactLog(newContactLog);
        setTimeout(() => {
          handleCheckExistContact(contacts, index + 1);
        }, delayTime + 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          handleAddContactBitrix(params, contacts, index);
        }, delayTime);
      });
  };

  const handleAddContactLog = (contactLog) => {
    addContactLog(contactLog).then((response) => {
      return response;
    });
  };

  //===============================================================

  const handleStop = () => {
    // setTimeoutId(null);
    window.location.reload();
  };

  const start = (date) => {
    handlePrepareSync(date);
    setIsSyncing(true);
  };
  const stop = () => {
    updateOpenLogin(true);
    setIsSyncing(false);
    return;
  };
  return (
    <>
      <Button disabled={isSyncing} onClick={() => setOpen(true)}>
        Đồng bộ khách hàng
      </Button>
      <DatePickerModal
        open={open}
        setOpen={setOpen}
        onStart={handleRun}
        onStop={handleStop}
        running={isSyncing}
        setRunning={setIsSyncing}
      />
    </>
  );
}
