import axios from "axios";

export const addProductBitrix = async (product) => {
  const response = await axios.post(
    `https://cosette.bitrix24.com/rest/1/curaopfj6s8tfaft/crm.product.add.json`,
    { fields: product }
  );
  return response;
};
export const getProductSkuByProductID = async (productId) => {
  const data = await axios.post(
    "https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.product.offer.list",
    {
      select: ["id", "iblockId", "code", "xmlId"],
      filter: {
        iblockId: 26,
        parentId: productId,
      },
    }
  );

  return data;
};
export const updateProductSkuBitrix = async (id, fields) => {
  
  const response = await axios.post(
    `https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.product.update`,
    { id: id, fields: fields }
  );
  return response;
};

export const getSkuByCode = async (code) => {
  const response = await axios.post(
    "https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.product.offer.list",
    {
      select: ["id", "iblockId", "code", "xmlId"],
      filter: {
        iblockId: 26,
        code: code,
      },
    }
  );
  return response;
};

export const addProductSkuBitrix = async (productSku) => {
  const response = await axios.post(
    `https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.product.offer.add`,
    { fields: productSku }
  );
  return response;
};
export const getBitrixProductByCode = async (code) => {
  const data = await axios.post(
    "https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/crm.product.list",
    { filter: { CODE: code } }
  );

  return data;
};

// PRODUCT GROUP
export const getProductGroupBitrix = async () => {
  const response = await axios.get(
    `https://cosette.bitrix24.com/rest/1/nta2s7x4cirraye1/crm.product.property.get.json?id=372`
  );
  return response;
};
export const addProductGroupBitrix = async (id, group) => {
  const data = await axios.post(
    `https://cosette.bitrix24.com/rest/1/9t17akzt5lepc3yx/crm.product.property.update.json`,
    {
      id: id,
      fields: group,
      // fields: {
      //   VALUES: {
      //     156: {
      //       ID: "156",
      //       VALUE: "CHANVAY",
      //       XML_ID: "43e307df50c07b319adb4d5f96c1baf2",
      //       SORT: "100",
      //       DEF: "N",
      //     },
      //     158: {
      //       ID: "158",
      //       VALUE: "QUAN",
      //       XML_ID: "c41cff1649becb662d895a6822e1e7b0",
      //       SORT: "200",
      //       DEF: "N",
      //     },
      //     160: {
      //       ID: "160",
      //       VALUE: "AO",
      //       XML_ID: "b1caf2d123688cf8719e4547900efcdc",
      //       SORT: "300",
      //       DEF: "N",
      //     },
      //     162: {
      //       ID: "162",
      //       VALUE: "DAM",
      //       XML_ID: "852d0f84d1c910ca13685cf93f78f401",
      //       SORT: "400",
      //       DEF: "N",
      //     },
      //   },
      // },
    }
  );
  return data;
};
