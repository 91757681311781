const usersConstant = [
  { id: 1391620, givenName: "Khoa", idBitrix: "16" },
  { id: 1397245, givenName: "Tam", idBitrix: "24" },
  { id: 1399842, givenName: "SC - Trâm", idBitrix: "3650" },
  { id: 1399874, givenName: "SC - Trang", idBitrix: "1696" },
  { id: 1400719, givenName: "SC - Lâm Đạt", idBitrix: "3882" },
  { id: 1415823, givenName: "DTN - Nghi", idBitrix: "9060" },
  { id: 1000003432, givenName: "Thắng", idBitrix: "11846" },
  { id: 1417511, givenName: "DTN-ThienHau", idBitrix: "12392" },
  { id: 1000003887, givenName: "SC - Bảo Trân", idBitrix: "13108" },
  { id: 1000003913, givenName: "DTN-Như Ý", idBitrix: "13212" },
  { id: 1000004380, givenName: "ONLINE - Vy", idBitrix: "13928" },
  { id: 1413513, givenName: "test", idBitrix: "34" },
];
export default usersConstant;
