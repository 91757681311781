import axios from "axios";

export const addProductPrice = async (document) => {
  const response = await axios.post(
    `https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.price.add`,
    { fields: document }
  );
  return response;
};
export const getPriceBySkuId = async (skuId) => {
  const response = await axios.post(
    "https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.price.list",
    {
      select: ["id"],
      filter: {
        productId: skuId,
      },
    }
  );
  return response;
};
export const updatePrice = async (sku, priceId) => {
  const response = await axios.post(
    "https://cosette.bitrix24.com/rest/1/1bvvq70t8efppips/catalog.price.update",
    {
      id: priceId,
      fields: {
        catalogGroupId: 2,
        currency: "VND",
        price: sku?.BasePrice,
      },
    }
  );
  return response;
};
