import axios from "axios";
import { baseUrl } from "../../constants/url";

const endpoint = "/master-data/product-group";

export const addProductGroupMT = async (groups) => {
  const response = await axios.post(`${baseUrl}${endpoint}/add`, groups);
  return response;
};
export const getProductGroupMT = async () => {
  const response = await axios.get(`${baseUrl}${endpoint}`);
  return response;
};
