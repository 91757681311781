import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { checkExpiredToken, convertProductParents } from "../../utils/utils";
import { useContext } from "react";
import { getProducts } from "../../api/KiotViet/product.api";
import {
  addProductBitrix,
  addProductSkuBitrix,
  getBitrixProductByCode,
  getProductSkuByProductID,
  getSkuByCode,
  updateProductSkuBitrix,
} from "../../api/Bitrix24/product.api";
import {
  addDocumentContractor,
  addInventoryDocumentBitrix,
  addStockReceiptBitrix,
  processDocument,
  updateStockReceiptBitrix,
} from "../../api/Bitrix24/inventory.api";
import { getProductGroupMT } from "../../api/MasterData/productGroup.api";
import dayjs from "dayjs";
import { AppContext } from "../../context/AppContext";
import {
  addProductPrice,
  getPriceBySkuId,
  updatePrice,
} from "../../api/Bitrix24/price.api";
import DatePickerModal from "../Modal/DatePickerModal";

const productKiotParams = {
  pageSize: 20,
  orderBy: "createdDate",
  orderDirection: "desc",
  includeInventory: true,
  includeCustomerGroup: true,
  includePricebook: true,
  isActive: true,
};
const delayTime = 500;
const restartTime = 60000;

export default function UpdateProductBitrix() {
  const kiotToken = localStorage.getItem("kiotToken");
  const [open, setOpen] = useState(false);
  const [productGroupMT, setProductGroupMT] = useState([]);
  const { setIsSyncing, updateOpenLogin, isSyncing } = useContext(AppContext);
  const handleStop = () => {
    // setTimeoutId(null);
    window.location.reload();
  };

  const handleRun = async (date) => {
    if (!checkExpiredToken()) {
      start(date);
    } else {
      localStorage.clear("kiotToken");
      localStorage.clear("expiredDate");
      stop();
    }
  };

  //#region BẮT ĐẦU QUY TRÌNH ADD
  const handlePrepare = async (date) => {
    handleGetProductsKiot([], 0, date);
  };

  const addProductDone = (date) => {
    // Lấy sản phẩm theo ngày cập nhật mới
    handleGetProductsKiot([], 0, date, true);
  };

  const updateProductDone = (date) => {
    setIsSyncing(false);
  };

  // Lấy sản phẩm kiot
  const handleGetProductsKiot = (products, currentItem, date, isUpdate) => {
    if (isUpdate) {
      const params = {
        ...productKiotParams,
        lastModifiedFrom: dayjs(date).format("YYYY-MM-DD"),
      };
      getProducts(params, currentItem, kiotToken).then(async ({ data }) => {
        const next = currentItem + data?.data?.length;

        let list = [...products, ...data.data];
        console.log("LIST PRODUCTS KIOT ===> ", list);

        if (list && list?.length > 0) {
          if (list?.length < data.total) {
            setTimeout(() => {
              // Tiếp tục lấy sản phẩm kiot
              handleGetProductsKiot(list, next, date, isUpdate);
            }, delayTime);
          } else {
            // Cập nhật sku
            handleUpdateSku(list, 0, date);
          }
        }
      });
    } else {
      getProducts(productKiotParams, currentItem, kiotToken, date)
        .then(async ({ data }) => {
          const next = currentItem + data?.data?.length;

          let list = [...products, ...data.data];
          console.log("LIST PRODUCTS KIOT ===> ", list);

          if (list && list?.length > 0) {
            const dateA = new Date(list[list?.length - 1]?.createdDate);
            const dateB = new Date(dayjs(date).format("YYYY-MM-DD"));
            if (dateA > dateB) {
              setTimeout(() => {
                // Tiếp tục lấy sản phẩm kiot
                handleGetProductsKiot(list, next, date);
              }, delayTime);
            } else {
              // Chuyển list product về dạng cha con
              const productWithChildren = await convertProductParents(list);
              // Thêm sản phẩm
              handleAddProduct(productWithChildren, 0, date);
            }
          }
        })
        .catch((err) => {
          setTimeout(() => {
            handleGetProductsKiot(products, currentItem, date);
          }, delayTime);
        });
    }
  };

  // Thêm sản phẩm
  const handleAddProduct = async (productWithChildren, indexProduct, date) => {
    const index = indexProduct || 0;
    if (index <= productWithChildren?.length - 1) {
      const currItem = productWithChildren[index];

      // Kiểm tra sản phẩm đã có chưa
      const response = await getBitrixProductByCode(currItem.code);
      // Nếu chưa có product thì add
      if (!response.data?.result?.[0]) {
        setTimeout(() => {
          handleAddProduct(productWithChildren, index, date);
        }, delayTime);
        return;
      } else {
        // Nếu có rồi thì update
        // Kiểm tra sku đã có hay chưa
        // const isExisted = await getSkuByCode(product.Code);
        const groupById = productGroupMT.find(
          (item) => currItem?.categoryId === item.idK
        )?.idB;

        const skuReq = {
          PROPERTY_378: currItem?.code, // Code
          NAME: currItem?.name,
          CODE: currItem?.code, //fullName
          DESCRIPTION: currItem?.description, //description
          PROPERTY_372: groupById, //categoryId
          SECTION_ID: "128",
          CURRENCY_ID: "VND",
          PROPERTY_380:
            "Sản phẩm được tạo tự động bởi hệ thống đồng bộ Kiot To Bitrix",
        };
        addProductBitrix(skuReq)
          .then((response) =>
            // Thêm SKU
            setTimeout(() => {
              handleAddSku(
                response.data.result,
                currItem.children,
                currItem?.basePrice,
                0,
                [],
                productWithChildren,
                index + 1
              );
            }, delayTime)
          )
          .catch((err) => {
            // Thêm sản phẩm lại
            setTimeout(() => {
              handleAddProduct(productWithChildren, index, date);
            }, delayTime);
          });
      }
    } else {
      setTimeout(() => {
        addProductDone();
      }, delayTime);
    }
  };

  // Cập nhật sku
  const handleAddSku = async (
    productId,
    productSkus,
    basePrice,
    indexSku,
    skuIds,
    productWithChildren,
    indexProduct
  ) => {
    const index = indexSku || 0;
    if (index <= productSkus?.length - 1) {
      const currItem = productSkus[index];
      const listSkuId = skuIds;
      const amount = currItem.inventories?.reduce(
        (prev, curr) => prev + curr?.onHand,
        0
      );
      const productItem = {
        code: currItem.code,
        iblockId: "26",
        measure: "9",
        name: currItem?.fullName,
        purchasingCurrency: "VND",
        purchasingPrice: currItem?.cost, // giá mua
        subscribe: "Y",
        xmlId: currItem.id,
        parentId: productId,
        property384: amount > 0 ? "Y" : "N",
      };

      // API THÊM SKU
      addProductSkuBitrix(productItem)
        .then((response) => {
          // Thêm giá bán SKU
          setTimeout(() => {
            handleAddProductPrice(
              response.data.result?.offer.id,
              currItem,
              productId,
              productSkus,
              basePrice,
              index + 1,
              listSkuId,
              productWithChildren,
              indexProduct
            );
          }, delayTime);
        })
        .catch((err) => {
          // Thêm sku lại
          setTimeout(() => {
            handleAddSku(
              productId,
              productSkus,
              basePrice,
              indexSku,
              skuIds,
              productWithChildren,
              indexProduct
            );
          }, delayTime);
        });
    } else {
      // Tạo document cho sản phẩm
      setTimeout(() => {
        handleAddDocument(skuIds, productWithChildren, indexProduct);
      }, delayTime);
    }
  };

  // Cập nhật SKU
  const handleUpdateSku = async (skus, indexSku) => {
    const index = indexSku || 0;
    if (index <= skus?.length - 1) {
      const currItem = skus[index];
      const skuReq = {
        code: currItem.code,
        iblockId: "26",
        measure: "9",
        name: currItem?.fullName,
        purchasingCurrency: "VND",
        purchasingPrice: currItem?.cost, // giá mua
      };

      // API CẬP NHẬT SKU
      updateProductSkuBitrix(skuReq)
        .then((response) => {
          // Cập nhật giá bán SKU
          setTimeout(() => {
            handleUpdateProductPrice(skus, index);
          }, delayTime);
        })
        .catch((err) => {
          // Thêm sku lại
          setTimeout(() => {
            handleUpdateSku(skus, indexSku);
          }, delayTime);
        });
    } else {
      // Xong quy trình update
      setTimeout(() => {
        updateProductDone();
      }, restartTime);
    }
  };

  // Thêm giá bán
  const handleAddProductPrice = (
    addedSkuId,
    currSku,
    productId,
    productSkus,
    basePrice,
    indexSku,
    skuIds,
    productWithChildren,
    indexProduct
  ) => {
    // API Thêm giá bán
    addProductPrice({
      catalogGroupId: 2,
      currency: "VND",
      price: basePrice,
      productId: addedSkuId,
    })
      .then(() => {
        // Tổng số lượng của SKU
        const amount = currSku.inventories?.reduce(
          (prev, curr) => prev + curr?.onHand,
          0
        );
        const cost = currSku.inventories?.find((inv) => inv.cost > 0)?.cost;
        skuIds.push({
          id: addedSkuId,
          amount: amount,
          purchasePrice: cost || 0, // giá mua
          salePrice: basePrice, // giá bán
        });
        // Tiếp tục thêm SKU
        setTimeout(() => {
          handleAddSku(
            productId,
            productSkus,
            basePrice,
            indexSku,
            skuIds,
            productWithChildren,
            indexProduct
          );
        }, delayTime);
      })
      .catch(() => {
        // Thêm giá bán lại
        setTimeout(() => {
          handleAddProductPrice(
            addedSkuId,
            currSku,
            productId,
            productSkus,
            basePrice,
            indexSku,
            skuIds,
            productWithChildren,
            indexProduct
          );
        });
      }, delayTime);
  };

  // Cập nhật giá bán
  const handleUpdateProductPrice = async (skus, indexSku) => {
    const sku = skus[indexSku];
    // Lấy Giá theo sku id
    await getPriceBySkuId(sku.id)
      .then(async ({ data }) => {
        const priceReq = {
          catalogGroupId: 2,
          currency: "VND",
          price: sku?.basePrice,
        };
        await updatePrice(priceReq, data.result.prices?.[0]).then(() => {
          // Cập nhật stock
          setTimeout(() => {
            handleUpdateStock(skus, indexSku);
          }, delayTime);
        });
      })
      .catch((err) => {
        // Cập nhật giá bán lại
        setTimeout(() => {
          handleUpdateProductPrice(skus, indexSku);
        }, delayTime);
      });
  };

  // Tạo document cho sản phẩm
  const handleAddDocument = async (
    skuIds, // {id, amount, cost}
    productWithChildren,
    indexProduct
  ) => {
    // Nếu có tồn kho thì tạo document
    const total = skuIds?.reduce(
      (prev, curr) => prev + curr?.purchasePrice * curr.amount,
      0
    );
    const document = {
      docType: "A", // A = Stock receipt
      contractorId: "1", // vendor
      responsibleId: "1",
      currency: "VND",
      total: total,
    };

    // API Thêm document
    addInventoryDocumentBitrix(document)
      .then((response) => {
        // Thêm tồn kho
        setTimeout(() => {
          handleAddStockReceipt(
            response.data.result.document.id,
            skuIds,
            0,
            productWithChildren,
            indexProduct
          );
        }, delayTime);
      })
      .catch((err) => {
        // Thêm document lại
        setTimeout(() => {
          handleAddDocument(
            skuIds, // {id, amount, cost}
            productWithChildren,
            indexProduct
          );
        }, delayTime);
      });
  };

  // Thêm tồn kho
  const handleAddStockReceipt = async (
    docId,
    skuIds,
    indexStock,
    productWithChildren,
    indexProduct
  ) => {
    const index = indexStock || 0;
    if (index <= skuIds?.length - 1) {
      const currItem = skuIds[index];
      const stockReceipt = {
        docId: docId,
        storeFrom: 0,
        storeTo: 10, // 10 = Kho tổng sản phẩm
        elementId: currItem.id,
        amount: currItem.amount > 0 ? currItem.amount : 1, // nếu ko có tồn thì để là 1 để có thể process document
        purchasingPrice: currItem.purchasePrice,
      };

      // API  thêm tồn kho
      addStockReceiptBitrix(stockReceipt)
        .then((response) => {
          // Tiếp tục thêm tồn kho
          setTimeout(() => {
            handleAddStockReceipt(
              docId,
              skuIds,
              index + 1,
              productWithChildren,
              indexProduct
            );
          }, delayTime);
        })
        .catch((err) => {
          // Thêm tồn kho lại
          setTimeout(() => {
            handleAddStockReceipt(
              docId,
              skuIds,
              indexStock,
              productWithChildren,
              indexProduct
            );
          }, delayTime);
        });
    } else {
      // Tạo liên kết document và nhà cung cấp
      setTimeout(() => {
        handleAddDocumentContractor(docId, productWithChildren, indexProduct);
      }, delayTime);
    }
  };

  // Cập nhật tồn kho
  const handleUpdateStock = async (skus, indexSku, date) => {
    const sku = skus[indexSku];
    const stockReq = {
      amount: sku.inventories?.reduce((prev, curr) => prev + curr?.onHand, 0),
      purchasingPrice: sku.inventories?.find((inv) => inv.cost > 0)?.cost, // giá mua
    };
    await updateStockReceiptBitrix(sku.id, stockReq)
      .then(() => {
        // Quay lại cập nhật tiếp sku
        setTimeout(() => {
          handleUpdateSku(skus, indexSku + 1, date);
        }, delayTime);
      })
      .catch(() => {
        setTimeout(() => {
          handleUpdateStock(skus, indexSku, date);
        }, delayTime);
      });
  };

  // Tạo liên kết document và nhà cung cấp
  const handleAddDocumentContractor = (
    docId,
    productWithChildren,
    indexProduct
  ) => {
    const documentContractor = {
      documentId: docId,
      entityTypeId: 3,
      entityId: 25788,
    };
    // API Tạo liên kết document và nhà cung cấp
    addDocumentContractor(documentContractor)
      .then((response) => {
        // Kích hoạt document
        setTimeout(() => {
          handleProcessDocument(docId, productWithChildren, indexProduct);
        }, delayTime);
      })
      .catch((err) => {
        // API Tạo liên kết document và nhà cung cấp lại
        setTimeout(() => {
          handleAddDocumentContractor(docId, productWithChildren, indexProduct);
        }, delayTime);
      });
  };

  // Kích hoạt document
  const handleProcessDocument = async (
    docId,
    productWithChildren,
    indexProduct
  ) => {
    // API Kích hoạt document
    processDocument(docId)
      .then(() => {
        // Quay lại tiếp tục thêm sản phẩm
        setTimeout(() => {
          handleAddProduct(productWithChildren, indexProduct);
        }, delayTime);
      })
      .catch(() => {
        setTimeout(() => {
          handleProcessDocument(docId, productWithChildren, indexProduct);
        }, delayTime);
      });
  };

  //#endregion KẾT THÚC QUY TRÌNH

  //#region BẮT ĐẦU QUY TRÌNH UPDATE
  const start = (date) => {
    handlePrepare(date);
    setIsSyncing(true);
  };
  const stop = () => {
    updateOpenLogin(true);
    setIsSyncing(false);
    return;
  };

  useEffect(() => {
    getProductGroupMT().then((response) => {
      setProductGroupMT(response.data);
    });
  }, []);

  return (
    <>
      <Button disabled={isSyncing} onClick={() => setOpen(true)}>
        Đồng bộ Hàng hóa
      </Button>
      <DatePickerModal
        open={open}
        setOpen={setOpen}
        onStart={handleRun}
        onStop={handleStop}
        running={isSyncing}
        setRunning={setIsSyncing}
      />
    </>
  );
}
