import { Flex, Modal, Spin, Typography } from "antd";
import React, { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { LoadingOutlined } from "@ant-design/icons";
const { Title } = Typography;
export default function SyncingModal() {
  const { isSyncing } = useContext(AppContext);
  return (
    <Modal
      centered
      open={isSyncing}
      maskClosable={false}
      wrapClassName="syncing-modal"
      closable={false}
      closeIcon={false}
      footer={null}
    >
      <Flex align="center" justify="center" vertical>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        <Title level={5}>Đang đồng bộ, vui lòng chờ...</Title>
      </Flex>
    </Modal>
  );
}
