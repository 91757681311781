import React, { createContext, useState } from "react";

// Khởi tạo một context với giá trị mặc định
const AppContext = createContext();

const AppProvider = ({ children }) => {
  // Sử dụng useState để quản lý state
  const [isOpenLogin, setIsOpenLogin] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);

  const updateOpenLogin = (isOpen) => {
    setIsOpenLogin(isOpen);
  };
  const updateKiotToken = (token) => {
    localStorage.setItem("kiotToken", token);
  };

  return (
    // Cung cấp giá trị context cho children bên trong
    <AppContext.Provider
      value={{
        isOpenLogin,
        setIsOpenLogin,
        updateOpenLogin,
        updateKiotToken,
        isSyncing,
        setIsSyncing,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
